@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// 默认字体大小
// @font-size-base: 16px;
// @font-size-sm: 14px;

// 标题下边距
@typography-title-margin-bottom: 0;

// 主色
// @primary-color: #0099cb;
// 直角边框
@border-radius-base: 0;
// 菜单背景色
@menu-dark-bg: #2c2f33;
// @menu-item-height: 40px;
// @menu-inline-toplevel-item-height: 40px;
// @menu-item-vertical-margin: 0;
// @menu-item-boundary-margin: 0;

.ant-table th {
  white-space: nowrap;
}

.ant-image {
  height: 100%;
}
// flex相关便捷全局类
.Row {
  display       : flex;
  flex-direction: row;
  align-items   : center;
}

.Col {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.RowCenter {
  @extend .Row;
  justify-content: center;
}

.RowStart {
  @extend .Row;
  justify-content: flex-start;
}

.RowEnd {
  @extend .Row;
  justify-content: flex-end;
}

.RowBetween {
  @extend .Row;
  justify-content: space-between;
}

.RowAround {
  @extend .Row;
  justify-content: space-around;
}

.RowEvenly {
  @extend .Row;
  justify-content: space-evenly;
}

.ColCenter {
  @extend .Col;
  justify-content: center;
}

.ColStart {
  @extend .Col;
  justify-content: flex-start;
}

.ColEnd {
  @extend .Col;
  justify-content: flex-end;
}

.ColBetween {
  @extend .Col;
  justify-content: space-between;
}

.ColAround {
  @extend .Col;
  justify-content: space-around;
}

.ColEvenly {
  @extend .Col;
  justify-content: space-evenly;
}

.ant-menu-inline {
  border-right: none;
}

.ant-menu {
  background-color: #f5f5f5;
  user-select     : none;
}

.ant-modal {
  padding-top: 24px;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial;
}

.ant-spin {
  font-size: 14px;
}

.ant-spin-text {
  margin-top: 5px;
}

.ant-tooltip {
  max-width: initial;
}

.ant-descriptions-item-label {
  white-space: nowrap;
}

#loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
  position        : fixed;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  z-index         : 9999;
}

.ant-table table {
  font-size: 12px;
}

.ant-table-content {
  // overflow: scroll;
}

.rowWarning {
  background-color: rgb(253, 226, 226);

  // color           : #ffffff;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right,
  .ant-table-cell {
    background-color: rgb(253, 226, 226);

  }
}

.rowRemind {
  background-color: rgb(250, 236, 216);

  // color           : #ffffff;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right,
  .ant-table-cell {
    background-color: rgb(250, 236, 216);

  }
}



.sheet-no-screen {
  width   : 100%;
  height  : 500px;
  position: relative;
}

.sheet-all-screen {
  position: fixed !important;
  height  : 100vh;
  width   : 100vw;
  left    : 0;
  top     : 0;
  right   : 0;
  bottom  : 0;

  .luckysheet {
    height: 100vh !important;
    width : 100vw !important;

    .luckysheet-cell-main {
      width : 100vw !important;
      height: 100vh !important;

    }
  }
}